import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import cn from 'classnames'
import styles from './dashboard.module.scss'
import useSocket from '../../../hooks/useSocket'
import { EUserRoles } from '../../../enums/roles'
import useHeader from '../../Header/useHeader'
import useSidebar from '../../SideBar/useSidebar'
import PageSpinner from '../../ui/PageSpinner/PageSpinner'
import { useUserRole } from '../../../store/selectors/auth'
import { SocketContext } from '../../../context/socketContext'
import { useModalContext } from '../../../context/modalContext'
import { IRecallDto } from '../../../dto/recall/recall.dto'
import RecallEventModal from '../../Modals/RecallEventModal/RecallEventModal'
import { SocketEvents } from '../../../enums/socket'
import { useAppDispatch } from '../../../store/hooks'
import { getCountries, getFields, getSettings } from '../../../store/settings/thunk'
import { getCrmInfo, getPhoneBalance } from '../../../store/crmInfo/thunk'
import { useMessage } from '../../../hooks/useMessage'
import { getUserPermissions } from '../../../store/userPermissions/thunk'

const DashboardLayout: React.FC = () => {
  const dispatch = useAppDispatch()
  const { setModalComponent } = useModalContext()
  const header = useHeader()
  const sideBar = useSidebar()
  const role = useUserRole()
  const {
    socket,
    emit,
    subscribeToRecall,
    unSubscribeToRecall,
    subscribeToEvent,
    unSubscribeToEvent,
    usersStatus,
    updateRecall,
    updateTransmit,
  } = useSocket()
  const [recall, setRecall] = useState<IRecallDto>()
  const { success } = useMessage()

  const getFinishStatus = useCallback((msg: { id?: string; name?: string }) => {
    if (msg?.name) {
      setTimeout(() => {
        success(`Номера для базы: ${msg.name} - проверены!`)
      }, 0)
    }
  }, [])

  useEffect(() => {
    const updateStatuses = () => dispatch(getSettings())
    const updateCrmInfo = () => dispatch(getCrmInfo())
    const updateCountries = () => dispatch(getCountries())
    const updateUserPermissions = () => {
      dispatch(getUserPermissions())
    }
    const updateCrmFields = () => dispatch(getFields())
    const updateBalance = () => {
      dispatch(getPhoneBalance()).then()
    }
    subscribeToRecall(setRecall)
    subscribeToEvent(SocketEvents.UPDATE_MAP_STATUSES, updateStatuses)
    subscribeToEvent(SocketEvents.UPDATE_CRM_FIELDS, updateCrmFields)
    subscribeToEvent(SocketEvents.UPDATE_JIVO_TOKEN, updateCrmInfo)
    subscribeToEvent(SocketEvents.FINISH_CHECK_PHONES, getFinishStatus)
    subscribeToEvent(SocketEvents.UPDATE_COUNTRIES, updateCountries)
    subscribeToEvent(SocketEvents.UPDATE_USERS_PERMISSIONS, updateUserPermissions)
    subscribeToEvent(SocketEvents.UPDATE_CRM_BALANCE, updateBalance)

    return () => {
      unSubscribeToRecall(setRecall)
      unSubscribeToEvent(SocketEvents.UPDATE_MAP_STATUSES, updateStatuses)
      unSubscribeToEvent(SocketEvents.UPDATE_CRM_FIELDS, updateCrmFields)
      unSubscribeToEvent(SocketEvents.UPDATE_JIVO_TOKEN, updateCrmInfo)
      unSubscribeToEvent(SocketEvents.FINISH_CHECK_PHONES, getFinishStatus)
      unSubscribeToEvent(SocketEvents.UPDATE_COUNTRIES, updateCountries)
      unSubscribeToEvent(SocketEvents.UPDATE_USERS_PERMISSIONS, updateUserPermissions)
      unSubscribeToEvent(SocketEvents.UPDATE_CRM_BALANCE, updateBalance)
    }
  }, [])

  useEffect(() => {
    if (recall) {
      setModalComponent({ component: <RecallEventModal recall={recall} />, closable: false })
    }
  }, [recall])

  return (
    <SocketContext.Provider
      value={{
        socket,
        emit,
        subscribeToRecall,
        unSubscribeToRecall,
        subscribeToEvent,
        unSubscribeToEvent,
        usersStatus,
        updateRecall,
        updateTransmit,
      }}
    >
      <div className={styles.container}>
        {header}

        <div
          className={cn(styles.body, {
            [styles.admin]: role === EUserRoles.ADMIN || role === EUserRoles.OPERATOR,
            [styles.manager]: role === EUserRoles.MANAGER,
          })}
        >
          <Suspense fallback={<PageSpinner className={styles.loadSpin} />}>
            <>
              {sideBar}
              <div className={styles.main}>
                <Outlet />
              </div>
            </>
          </Suspense>
        </div>
      </div>
    </SocketContext.Provider>
  )
}

export default DashboardLayout
